// Fonts loading...
@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/FiraCode-Regular.ttf);
  font-display: swap;
}
@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/FiraCode-Medium.ttf);
  font-display: swap;
}
@font-face {
  font-family: 'Fira Code';
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/FiraCode-Bold.ttf);
  font-display: swap;
}
.font-h1,
.font-h2,
.font-p,
.font-error {
  font-family: 'Fira Code', monospace;
  color: var(--c-foreground);
}
.font-h1 {
  font-weight: 700;
  font-size: 2.5rem;
  line-height: 1.3;
}
.font-h2 {
  font-weight: 500;
  line-height: 1;
  &--big {
    font-size: 1.5rem;
  }
  &--small {
    font-size: 1.125rem;
  }
}
.font-p {
  font-weight: 400;
  line-height: 1.5;
  &--big {
    font-size: 1rem;
  }
  &--small {
    font-size: 0.875rem;
  }
}
.font-error {
  font-size: 0.75rem;
  line-height: 1.2;
}
