:root {
  --section-inline-value: 4%;
}
.flex {
  display: flex;
}
.wrap {
  flex-wrap: wrap;
}
.row {
  display: flex;
  align-items: center;
}
.navbar,
.section {
  max-width: 90rem;
  margin-inline: auto;
}
.navbar {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}
.header {
  padding-inline: clamp(1.5rem, var(--section-inline-value), 8rem);
  overflow: hidden;
  z-index: 0;
  margin-bottom: 2rem;
  padding-block: 2rem;
  scroll-margin-top: 2.5rem;
}
.section {
  padding-inline: clamp(1.5rem, var(--section-inline-value), 8rem);
  overflow: hidden;
  z-index: 0;
  margin-block: 2rem;
  padding-block: 4rem;
  scroll-margin-top: 2.5rem;
  position: relative;
}
.projects {
  width: 100%;
  overflow: hidden;
  z-index: 0;
  margin-block: 2rem;
  padding-block: 4rem;
  scroll-margin-top: 2.5rem;
  &__title {
    margin-inline: clamp(1.5rem, var(--section-inline-value), 8rem);
  }
}
.header-ctn {
  width: 100%;
  min-height: calc(100vh - 4rem);
  min-height: calc(100svh - 4rem);
  position: relative;
  &__info {
    max-width: fit-content;
    flex: 1 1 auto;
    margin-inline: auto;
  }
  &__img {
    position: absolute;
    z-index: -1;
    top: 0;
    bottom: 0;
    right: 50%;
    width: max-content;
    transform: translateX(50%);
  }
}
.about-cnt {
  width: 100%;
  align-items: stretch;
  justify-content: center;
  column-gap: 1rem;
  row-gap: 9rem;
  position: relative;
  &__info,
  &__img {
    flex: 1 1 100%;
    position: relative;
  }
  &__info {
    flex-direction: column-reverse;
    justify-content: center;
    gap: 1rem;
  }
  &__img {
    max-width: 420px;
    padding-inline: calc(2rem + 0.25rem);
    margin-inline: auto;
  }
}
.skills-cnt {
  width: 100%;
  gap: 2rem;
  align-items: stretch;
  justify-content: center;
  position: relative;
}
.contact-ctn {
  width: 100%;
  gap: 2rem;
  position: relative;
  &__column {
    flex: 1 1 100%;
    gap: 2rem;
  }
  &__item {
    flex: 1 1 auto;
    gap: 2rem;
  }
  &__action {
    width: 100%;
  }
}
.footer-ctn {
  gap: 2.5rem;
  align-items: center;
  justify-content: space-evenly;
  &__socials {
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 2rem;
  }
  &__rights {
    width: 100%;
    text-align: center;
  }
}
