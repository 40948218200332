* {
  box-sizing: border-box;
}
a {
  cursor: pointer;
  color: inherit;
}
a:visited {
  appearance: none;
}
body {
  min-width: 21.875rem;
  min-height: 100vh;
  min-height: 100svh;
}
img,
svg {
  max-width: 100%;
  color: inherit;
  font-size: inherit;
}
img {
  width: 100%;
}
