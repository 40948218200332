:root {
  --c-accent: #52c4c4;
  --c-foreground: #eaeaea;
  --c-background: #0a0f1f;
  --c-background-light: #16203a;
  --c-border: #2d374810;
  //-------------------------------------
  --c-n-white: #fff;
  --c-n-black: #000;
}
