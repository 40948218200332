.carousel-wrapper {
  width: 100%;
  position: relative;
  padding-block: 1rem;
}

.search-box {
  max-width: min(25rem, 80%);
  margin-inline: auto;
  margin-bottom: 1.5rem;

  &__input {
    width: 100%;
    padding: 0.8rem 1.2rem;
    background: none;
    margin-bottom: 0.5rem;
  }

  &__error {
    color: red;
    text-align: center;
    display: none;
  }
}
// .project-carousel {
//   display: block;
//   height: 32rem;
//   position: relative;
//   width: 100%;
//   margin-inline: auto;
//   &__item {
//     display: block;
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     transition: 0.2s;
//     z-index: 0;
//     &.inactive {
//       display: none;
//     }
//   }
// }
.project-card {
  padding-block: 0rem 1rem;
  padding-inline: 1rem;
  flex-shrink: 0;
  width: 18.75rem;
  height: auto;
  min-height: 30.625rem;
  background-color: var(--c-background);
  flex: 0 0 18.75rem;
  &__image-wrapper {
    position: relative;
    width: 100%;
    height: 13.75rem;
    overflow: hidden;
    border-radius: 0.5rem;
    border: 1px solid var(--c-accent);
  }

  &__image {
    width: 100%;
  }

  &__github-btn {
    position: absolute;
    bottom: 0.5rem;
    left: 0.5rem;
    width: 4rem;
    height: 4rem;
    padding: 1rem;
    border-radius: 50%;
    border: 4px solid var(--c-accent);
    background: var(--c-n-white);
    box-shadow: 4px 2px 6px 0px rgba(0, 0, 0, 0.5);
    font-size: 1.5rem;
    color: var(--c-background);
  }

  &__info {
    & > * {
      margin-top: 1.5rem;
    }
  }

  &__btn-live-site {
    display: block;
    width: fit-content;
    position: relative;
    padding-right: 0.5rem;
  }

  &__live-icon {
    position: absolute;
    top: 50%;
    right: 0rem;
    transform: translate(100%, -50%);
  }
}

.styled-box-1 {
  border: 4px solid var(--c-accent);
  border-radius: 0.5rem;
  padding-top: 0;

  &__title {
    display: block;
    position: relative;
    width: fit-content;
    max-width: 100%;
    transform: translate(0, -0.8em);
    padding-inline: 0.5rem;
    background: var(--c-background);

    &--centered {
      margin-inline: auto;
      width: 100%;

      & > * {
        text-align: center;
      }
    }
  }
}
//-------
.carousel {
  width: 100%;
  min-height: 520px;
  overflow: scroll;
  padding: 2rem;
  gap: 2rem;
  scroll-snap-type: x mandatory;
  scroll-padding-inline: 2rem;
  &::before {
    // not proud of this!
    content: '';
    display: block;
    position: relative;
    min-width: 1px;
    margin-right: 50%;
  }
  &__item {
    position: relative;
    scroll-snap-align: center;
    transition: transform 0.3s ease, opacity 0.3s ease;
    transform-origin: center;
    &.inactive {
      display: none;
    }
  }
  &::after {
    // not proud of this!
    content: '';
    display: block;
    position: relative;
    min-width: 1px;
    margin-left: 50%;
  }
  &__prev,
  &__next {
    position: absolute;
    top: 50%;
    z-index: 200;
    font-family: 'Fira Code', monospace;
    color: var(--c-foreground);
    font-size: 2rem;

    aspect-ratio: 1;
    border-radius: 50%;
    border: 2px solid var(--c-accent);
    padding: 10px;
    box-shadow: 0px 0px 2px 2px #0000004d;

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: -1;
      border-radius: 50%;
      background: var(--c-background);
      opacity: 0.9;
    }
  }

  &__prev {
    left: 1%;
    transform: translate(0%, -50%);
  }

  &__next {
    left: 99%;
    transform: translate(-100%, -50%);
  }
}
