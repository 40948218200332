html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  vertical-align: baseline;
  font-size: 100%;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

input {
  outline: none;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  box-sizing: border-box;
  font: inherit;
  border: 0;
  margin: 0;
  padding: 0;
  text-decoration: none;
}

:before, :after {
  box-sizing: border-box;
}

svg {
  color: inherit;
}

button {
  -webkit-appearance: none;
  text-align: inherit;
  box-shadow: none;
  cursor: pointer;
  color: inherit;
  font: inherit;
  background: none;
  border: none;
  border-radius: 0;
  padding: 0;
}

:root {
  --c-accent: #52c4c4;
  --c-foreground: #eaeaea;
  --c-background: #0a0f1f;
  --c-background-light: #16203a;
  --c-border: #2d374810;
  --c-n-white: #fff;
  --c-n-black: #000;
}

@font-face {
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  src: url("FiraCode-Regular.f593cd71.ttf");
  font-display: swap;
}

@font-face {
  font-family: Fira Code;
  font-style: normal;
  font-weight: 500;
  src: url("FiraCode-Medium.ef1fb996.ttf");
  font-display: swap;
}

@font-face {
  font-family: Fira Code;
  font-style: normal;
  font-weight: 600;
  src: url("FiraCode-Bold.2fa4621c.ttf");
  font-display: swap;
}

.font-h1, .font-h2, .font-p, .font-error {
  color: var(--c-foreground);
  font-family: Fira Code, monospace;
}

.font-h1 {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.3;
}

.font-h2 {
  font-weight: 500;
  line-height: 1;
}

.font-h2--big {
  font-size: 1.5rem;
}

.font-h2--small {
  font-size: 1.125rem;
}

.font-p {
  font-weight: 400;
  line-height: 1.5;
}

.font-p--big {
  font-size: 1rem;
}

.font-p--small {
  font-size: .875rem;
}

.font-error {
  font-size: .75rem;
  line-height: 1.2;
}

* {
  box-sizing: border-box;
}

a {
  cursor: pointer;
  color: inherit;
}

a:visited {
  appearance: none;
}

body {
  min-width: 21.875rem;
  min-height: 100svh;
}

img, svg {
  color: inherit;
  font-size: inherit;
  max-width: 100%;
}

img {
  width: 100%;
}

:root {
  --section-inline-value: 4%;
}

.flex {
  display: flex;
}

.wrap {
  flex-wrap: wrap;
}

.row {
  align-items: center;
  display: flex;
}

.navbar, .section {
  max-width: 90rem;
  margin-inline: auto;
}

.navbar {
  z-index: 9999;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}

.header {
  padding-inline: clamp(1.5rem, var(--section-inline-value), 8rem);
  z-index: 0;
  margin-bottom: 2rem;
  padding-block: 2rem;
  scroll-margin-top: 2.5rem;
  overflow: hidden;
}

.section {
  padding-inline: clamp(1.5rem, var(--section-inline-value), 8rem);
  z-index: 0;
  margin-block: 2rem;
  padding-block: 4rem;
  scroll-margin-top: 2.5rem;
  position: relative;
  overflow: hidden;
}

.projects {
  z-index: 0;
  width: 100%;
  margin-block: 2rem;
  padding-block: 4rem;
  scroll-margin-top: 2.5rem;
  overflow: hidden;
}

.projects__title {
  margin-inline: clamp(1.5rem, var(--section-inline-value), 8rem);
}

.header-ctn {
  width: 100%;
  min-height: calc(100svh - 4rem);
  position: relative;
}

.header-ctn__info {
  flex: auto;
  max-width: -moz-fit-content;
  max-width: fit-content;
  margin-inline: auto;
}

.header-ctn__img {
  z-index: -1;
  width: max-content;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 50%;
  transform: translateX(50%);
}

.about-cnt {
  justify-content: center;
  align-items: stretch;
  gap: 9rem 1rem;
  width: 100%;
  position: relative;
}

.about-cnt__info, .about-cnt__img {
  flex: 100%;
  position: relative;
}

.about-cnt__info {
  flex-direction: column-reverse;
  justify-content: center;
  gap: 1rem;
}

.about-cnt__img {
  max-width: 420px;
  margin-inline: auto;
  padding-inline: 2.25rem;
}

.skills-cnt {
  justify-content: center;
  align-items: stretch;
  gap: 2rem;
  width: 100%;
  position: relative;
}

.contact-ctn {
  gap: 2rem;
  width: 100%;
  position: relative;
}

.contact-ctn__column {
  flex: 100%;
  gap: 2rem;
}

.contact-ctn__item {
  flex: auto;
  gap: 2rem;
}

.contact-ctn__action {
  width: 100%;
}

.footer-ctn {
  justify-content: space-evenly;
  align-items: center;
  gap: 2.5rem;
}

.footer-ctn__socials {
  justify-content: center;
  align-items: center;
  gap: 2rem;
  width: 100%;
}

.footer-ctn__rights {
  text-align: center;
  width: 100%;
}

.hamburger {
  cursor: pointer;
  display: block;
}

.hamburger__line {
  transform-origin: center;
  transition: y .2s ease-in .2s, transform .2s ease-in, opacity 0s .3s;
}

.active.hamburger .hamburger__line {
  transition: y .2s ease-in, transform .2s ease-in .2s, opacity 0s .3s;
}

.active.hamburger .hamburger__top, .active.hamburger .hamburger__bottom {
  y: 10.75px;
}

.active.hamburger .hamburger__top {
  transform: rotate(45deg);
}

.active.hamburger .hamburger__middle {
  opacity: 0;
}

.active.hamburger .hamburger__bottom {
  transform: rotate(-45deg);
}

.carousel-wrapper {
  width: 100%;
  padding-block: 1rem;
  position: relative;
}

.search-box {
  margin-inline: auto;
  max-width: min(25rem, 80%);
  margin-bottom: 1.5rem;
}

.search-box__input {
  background: none;
  width: 100%;
  margin-bottom: .5rem;
  padding: .8rem 1.2rem;
}

.search-box__error {
  color: red;
  text-align: center;
  display: none;
}

.project-card {
  background-color: var(--c-background);
  flex: 0 0 18.75rem;
  width: 18.75rem;
  height: auto;
  min-height: 30.625rem;
  padding-block: 0 1rem;
  padding-inline: 1rem;
}

.project-card__image-wrapper {
  border: 1px solid var(--c-accent);
  border-radius: .5rem;
  width: 100%;
  height: 13.75rem;
  position: relative;
  overflow: hidden;
}

.project-card__image {
  width: 100%;
}

.project-card__github-btn {
  border: 4px solid var(--c-accent);
  background: var(--c-n-white);
  color: var(--c-background);
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  padding: 1rem;
  font-size: 1.5rem;
  position: absolute;
  bottom: .5rem;
  left: .5rem;
  box-shadow: 4px 2px 6px #00000080;
}

.project-card__info > * {
  margin-top: 1.5rem;
}

.project-card__btn-live-site {
  width: -moz-fit-content;
  width: fit-content;
  padding-right: .5rem;
  display: block;
  position: relative;
}

.project-card__live-icon {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(100%, -50%);
}

.styled-box-1 {
  border: 4px solid var(--c-accent);
  border-radius: .5rem;
  padding-top: 0;
}

.styled-box-1__title {
  background: var(--c-background);
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  padding-inline: .5rem;
  display: block;
  position: relative;
  transform: translate(0, -.8em);
}

.styled-box-1__title--centered {
  width: 100%;
  margin-inline: auto;
}

.styled-box-1__title--centered > * {
  text-align: center;
}

.carousel {
  scroll-snap-type: x mandatory;
  gap: 2rem;
  width: 100%;
  min-height: 520px;
  padding: 2rem;
  scroll-padding-inline: 2rem;
  overflow: scroll;
}

.carousel:before {
  content: "";
  min-width: 1px;
  margin-right: 50%;
  display: block;
  position: relative;
}

.carousel__item {
  scroll-snap-align: center;
  transform-origin: center;
  transition: transform .3s, opacity .3s;
  position: relative;
}

.carousel__item.inactive {
  display: none;
}

.carousel:after {
  content: "";
  min-width: 1px;
  margin-left: 50%;
  display: block;
  position: relative;
}

.carousel__prev, .carousel__next {
  z-index: 200;
  color: var(--c-foreground);
  aspect-ratio: 1;
  border: 2px solid var(--c-accent);
  border-radius: 50%;
  padding: 10px;
  font-family: Fira Code, monospace;
  font-size: 2rem;
  position: absolute;
  top: 50%;
  box-shadow: 0 0 2px 2px #0000004d;
}

.carousel__prev:before, .carousel__next:before {
  content: "";
  z-index: -1;
  background: var(--c-background);
  opacity: .9;
  border-radius: 50%;
  position: absolute;
  inset: 0;
}

.carousel__prev {
  left: 1%;
  transform: translate(0%, -50%);
}

.carousel__next {
  left: 99%;
  transform: translate(-100%, -50%);
}

.toggleable {
  display: none;
}

.button-wrapper {
  max-width: -moz-fit-content;
  max-width: fit-content;
  position: relative;
}

.button {
  border: 2px solid var(--c-foreground);
  letter-spacing: .05em;
  cursor: pointer;
  background: none;
  border-radius: 3.125rem;
  justify-content: center;
  align-items: center;
  gap: .125rem;
  min-width: -moz-fit-content;
  min-width: fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding: .5rem 1.5rem;
  display: flex;
  position: relative;
}

.button:hover ~ .wget {
  color: var(--c-accent);
}

.top-navbar {
  padding-block: 1rem;
  padding-inline: clamp(1rem, var(--section-inline-value), 8rem);
  justify-content: space-between;
  align-items: stretch;
  width: 100%;
}

.top-navbar__logo__arrow {
  color: var(--c-accent);
}

.top-navbar__logo__text {
  margin-left: .5rem;
  line-height: 1;
}

.mv {
  white-space: nowrap;
  width: calc(2ch + .2em);
  transition: width .5s steps(10, end);
  position: relative;
  overflow: hidden;
}

.mv__m {
  letter-spacing: .2em;
}

.mv:after {
  content: "";
  mix-blend-mode: difference;
  width: 1ch;
  animation: 1s step-end infinite blink;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.menu__box {
  visibility: hidden;
  z-index: -1;
  border: 1px solid var(--c-accent);
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  background-color: #0a0f1ff2;
  border-radius: .5rem;
  flex-direction: column;
  align-items: stretch;
  gap: 2rem;
  width: -moz-fit-content;
  width: fit-content;
  max-height: 0;
  padding-block: 0;
  padding-inline: 4rem;
  transition: all .5s;
  position: absolute;
  top: calc(50svh + 50%);
  left: 50%;
  overflow: scroll;
  transform: translate(-50%, -50%);
  box-shadow: 4px 4px 8px 2px #00000040;
}

.menu__box.active {
  visibility: visible;
  max-height: calc(100svh - 100%);
  padding-block: 4rem;
  transition: all .5s;
}

.stylelink:after {
  content: "/";
}

.navlink {
  max-width: -moz-fit-content;
  max-width: fit-content;
  display: flex;
}

.hero__cover {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.hero__name, .hero__title {
  text-align: center;
}

.hero__title {
  margin-bottom: 1rem;
}

.hero__cta {
  gap: 2rem;
}

.hero__socials {
  gap: 2rem;
  margin-top: 4rem;
}

.hero__img {
  mix-blend-mode: multiply;
  opacity: .1;
  object-fit: contain;
  object-position: center center;
  width: auto;
  max-width: none;
  height: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
  position: relative;
}

.overlay--text, .overlay--btn {
  text-shadow: 2px 4px 4px #000000e6, -2px -2px 4px #000000b3;
}

.overlay--btn {
  box-shadow: 2px 4px 4px #000000e6, -2px -2px 4px #000000b3;
}

.overlay--icon {
  filter: drop-shadow(2px 4px 4px #000000e6) drop-shadow(-2px -2px 4px #000000b3);
}

.wget {
  transform-origin: 0 0;
  color: #52c4c44d;
  -webkit-user-select: none;
  user-select: none;
  cursor: default;
  width: max-content;
  display: block;
  position: absolute;
  top: 120%;
  left: 10%;
  rotate: 5deg;
}

.wget__text {
  font-family: Fira Code, monospace;
  font-size: 1rem;
  font-weight: 500;
  line-height: normal;
}

.about-text__read-more {
  color: var(--c-accent);
  cursor: pointer;
}

.about-text__read-more.active ~ .about-text__text > .toggleable {
  display: inline;
}

.portrait {
  width: 100%;
  margin: auto;
  position: relative;
}

.portrait:after {
  content: "";
  border-top: 2px solid var(--c-accent);
  border-bottom: 2px solid var(--c-accent);
  z-index: 20;
  position: absolute;
  inset: -1rem -3rem;
  transform: skew(-5deg, -10deg);
}

.portrait:before {
  content: "";
  border-left: 2px solid var(--c-accent);
  border-right: 2px solid var(--c-accent);
  z-index: 20;
  position: absolute;
  inset: -3rem -1rem;
  transform: skew(-5deg, -10deg);
}

.portrait__img {
  z-index: 50;
  clip-path: polygon(14% 0%, 96% 0%, 87% 85%, 4% 98%);
  position: relative;
  scale: 1.3;
}

.section-title {
  width: -moz-fit-content;
  width: fit-content;
  margin-bottom: 4rem;
  padding-block: .125rem;
  padding-inline: .5rem;
  position: relative;
}

.section-title:before {
  content: "";
  border-top: 1px dotted;
  border-left: 1px dotted;
  border-color: var(--c-accent);
  width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
}

.styled-box {
  border: 1px solid;
  border-color: var(--c-accent);
  border-radius: .5rem;
  position: relative;
}

.styled-box__title {
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  text-shadow: 2px 2px 4px #000000e6;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
  padding-inline: .5rem;
  display: block;
  position: relative;
  transform: translate(0, -.8em);
}

.styled-box__title--centered {
  width: 100%;
  margin-inline: auto;
}

.styled-box__title--centered > * {
  text-align: center;
}

.skill-box {
  flex: 15rem;
  max-width: 30rem;
  padding-block: 0 1.5rem;
  padding-inline: 1.5rem;
}

.skill-box__wrapper {
  gap: 2rem;
}

.skill-box__item {
  flex: 40%;
  align-items: center;
  gap: .5rem;
  min-width: -moz-fit-content;
  min-width: fit-content;
}

.skill-box__item__icon {
  min-width: -moz-fit-content;
  min-width: fit-content;
  font-size: 1.5rem;
  line-height: 0;
}

.input {
  padding-block: 0 .5rem;
  padding-inline: .5rem;
}

.input__input {
  color: var(--c-background);
  word-wrap: break-word;
  word-break: break-all;
  background: #d9d9d9;
  border: 1px solid;
  border-radius: .3125rem;
  outline: none;
  width: 100%;
  padding: .25rem 1rem;
}

.input__input:focus {
  border-color: var(--c-accent);
}

.input__input.input--error {
  border-color: red;
}

.input__error {
  color: red;
  margin-top: .25rem;
  position: absolute;
  top: 100%;
  right: 0;
}

.submit-button {
  margin-left: auto;
}

.submit-button__text {
  justify-content: center;
  align-items: center;
  gap: .5rem;
}

.logo {
  width: 2.5rem;
}

.logo-big {
  flex-direction: column;
  align-items: center;
  gap: .5rem;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.raised {
  border-bottom: 1px solid var(--c-border);
  box-shadow: 0 4px 6px -1px #0000001a, 0 2px 4px -2px #0000001a;
}

.bg-glass {
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px);
  background-color: #0a0f1f80;
}

.bg-space-gradient {
  background-image: radial-gradient(circle at left top, var(--c-background) 20%, var(--c-background-light), transparent 100%), radial-gradient(circle at center center, var(--c-background), transparent 100%), radial-gradient(circle at bottom right, var(--c-background) 20%, var(--c-background-light), transparent 100%);
  background-color: var(--c-background);
  background-size: cover;
}

.sotolined {
  position: relative;
}

.sotolined:before {
  content: "";
  visibility: hidden;
  border: 1px solid var(--c-accent);
  width: 0%;
  height: 0;
  transition: all .3s;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
}

.sotolined:hover:before {
  visibility: visible;
  width: 100%;
}

.socials {
  align-items: center;
  gap: 2rem;
  font-size: 2rem;
}

.socials__link {
  color: var(--c-foreground);
}

.button, .button__text {
  position: relative;
}

.button__text:after {
  content: "";
  visibility: hidden;
  border: 1px solid var(--c-accent);
  width: 0%;
  height: 0;
  transition: all .3s;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 100%);
}

.button:hover {
  border-color: var(--c-accent);
}

.button:hover .button__text:after {
  visibility: visible;
  width: 100%;
}

.projects__nav--btn:active {
  scale: .9;
}

@media (hover: hover) {
  .socials__link:hover {
    color: var(--c-accent);
    transform: scale(1.2);
  }

  .footer__extra:hover {
    color: var(--c-accent);
  }

  .blinked:hover:after {
    animation: 1s step-end infinite blink;
  }
}

@keyframes blink {
  0% {
    color: var(--c-background);
    background: var(--c-foreground);
  }

  50% {
    color: var(--c-foreground);
    background: none;
  }

  100% {
    color: var(--c-background);
    background: var(--c-foreground);
  }
}

.bg-svg {
  color: var(--c-foreground);
  opacity: .05;
  z-index: -1;
  font-size: 3rem;
  position: absolute;
}

.bg-svg--bottomleft {
  bottom: 10%;
  left: 2%;
}

.bg-svg--bottomright {
  bottom: 10%;
  right: 2%;
}

.bg-svg--topright {
  top: 10%;
  right: 2%;
}

.bg-svg--upercenter {
  top: 20%;
  left: 55%;
}

.bg-svg--center {
  top: 50%;
  left: 50%;
  transform: translate(-50%);
}

@media (width >= 37.5rem) {
  :root {
    --section-inline-value: 6%;
  }

  .about-cnt__info {
    padding-inline: 2.25rem 2rem;
  }

  .about-cnt__info:before {
    content: "";
    border-bottom: 1px dotted;
    border-left: 1px dotted;
    border-color: var(--c-accent);
    z-index: -1;
    width: 1.25rem;
    height: calc(50% + 4rem);
    position: absolute;
    bottom: 50%;
    left: 0;
  }

  .skills-cnt {
    padding-inline: 2rem;
  }

  .skills-cnt:before {
    content: "";
    border-bottom: 1px dotted;
    border-left: 1px dotted;
    border-color: var(--c-accent);
    z-index: -1;
    width: 1.25rem;
    height: calc(50% + 4rem);
    position: absolute;
    bottom: 50%;
    left: 0;
  }

  .carousel-wrapper:before {
    content: "";
    left: clamp(1rem, var(--section-inline-value), 8rem);
    border-bottom: 1px dotted;
    border-left: 1px dotted;
    border-color: var(--c-accent);
    z-index: -1;
    width: 0;
    height: calc(15% + 4rem);
    position: absolute;
    bottom: 85%;
  }

  .carousel-wrapper:after {
    content: "";
    left: clamp(1rem, var(--section-inline-value), 8rem);
    right: clamp(1rem, var(--section-inline-value), 8rem);
    border-bottom: 1px dotted;
    border-left: 1px dotted;
    border-color: var(--c-accent);
    z-index: -1;
    height: 1rem;
    position: absolute;
    bottom: 0;
  }

  .contact-ctn {
    padding: 1rem;
  }

  .contact-ctn:before {
    content: "";
    border-bottom: 1px dotted;
    border-left: 1px dotted;
    border-color: var(--c-accent);
    z-index: -1;
    width: 0;
    height: 4rem;
    position: absolute;
    bottom: 100%;
    left: 0;
  }

  .contact-ctn:after {
    content: "";
    border-bottom: 1px dotted;
    border-left: 1px dotted;
    border-color: var(--c-accent);
    z-index: -1;
    width: 100%;
    height: 4rem;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

@media (width >= 43.75rem) {
  .font-h1 {
    font-size: 3rem;
  }

  .top-navbar {
    padding-block: 1.5rem;
  }
}

@media (width >= 64rem) {
  :root {
    --section-inline-value: 12%;
  }

  .mv:hover {
    width: calc(11ch + .2em);
  }

  .header-ctn__info {
    margin-inline: 0;
  }

  .header-ctn__img {
    max-width: 50%;
    right: 0;
    transform: translateX(0);
  }

  .hero__cover {
    align-items: start;
  }

  .hero__name, .hero__title {
    text-align: start;
  }

  .hero__cta {
    padding-right: 2rem;
  }

  .hero__socials {
    gap: 2rem;
    margin-top: 4rem;
  }

  .hero__img {
    opacity: .5;
    width: 100%;
    max-width: 100%;
  }

  .about-cnt {
    column-gap: 3rem;
  }

  .about-cnt__info {
    flex-basis: 18.75rem;
  }

  .about-cnt__img {
    flex-basis: 15rem;
  }

  .footer-ctn__socials {
    max-width: -moz-fit-content;
    max-width: fit-content;
  }

  .wget {
    left: 90%;
    rotate: -30deg;
  }

  .font-h1 {
    font-size: 3.25rem;
  }

  .font-p--big {
    font-size: 1.125rem;
  }

  .top-navbar {
    padding-block: 1.5rem;
  }

  .hamburger {
    display: none;
  }

  .menu__box {
    visibility: visible;
    box-shadow: none;
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
    background: none;
    border: none;
    border-radius: 0;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    width: -moz-fit-content;
    width: fit-content;
    max-height: 100%;
    padding-block: 0;
    padding-inline: 0;
    transition: none;
    position: static;
    overflow: visible;
    transform: translate(0);
  }

  .menu .navlink {
    font-size: 1.25rem;
  }
}

@media (width >= 85rem) {
  .hero__img {
    opacity: .8;
  }
}

/*# sourceMappingURL=index.266b6b76.css.map */
