@media (min-width: 37.5rem) {
  :root {
    --section-inline-value: 6%;
  }
  .about-cnt {
    &__info {
      padding-inline: calc(2rem + 0.25rem) 2rem;
      &::before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 50%;
        width: 1.25rem;
        height: calc(50% + 4rem);
        border-bottom: 1px dotted;
        border-left: 1px dotted;
        border-color: var(--c-accent);
        z-index: -1;
      }
    }
  }
  .skills-cnt {
    padding-inline: 2rem;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 50%;
      width: 1.25rem;
      height: calc(50% + 4rem);
      border-bottom: 1px dotted;
      border-left: 1px dotted;
      border-color: var(--c-accent);
      z-index: -1;
    }
  }
  .carousel-wrapper {
    &::before {
      content: '';
      position: absolute;
      left: clamp(1rem, var(--section-inline-value), 8rem);
      bottom: 85%;
      width: 0;
      height: calc(15% + 4rem);
      border-bottom: 1px dotted;
      border-left: 1px dotted;
      border-color: var(--c-accent);
      z-index: -1;
    }
    &::after {
      content: '';
      position: absolute;
      left: clamp(1rem, var(--section-inline-value), 8rem);
      right: clamp(1rem, var(--section-inline-value), 8rem);
      bottom: 0;
      height: 1rem;
      border-bottom: 1px dotted;
      border-left: 1px dotted;
      border-color: var(--c-accent);
      z-index: -1;
    }
  }
  .contact-ctn {
    padding: 1rem;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      bottom: 100%;
      width: 0;
      height: 4rem;
      border-bottom: 1px dotted;
      border-left: 1px dotted;
      border-color: var(--c-accent);
      z-index: -1;
    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 4rem;
      border-bottom: 1px dotted;
      border-left: 1px dotted;
      border-color: var(--c-accent);
      z-index: -1;
    }
  }
}
@media (min-width: 43.75rem) {
  .font-h1 {
    font-size: 3rem;
  }
  .top-navbar {
    padding-block: 1.5rem;
  }
}
@media (min-width: 64rem) {
  :root {
    --section-inline-value: 12%;
  }
  .mv {
    &:hover {
      width: calc(11ch + 0.2em);
    }
  }
  .header-ctn {
    &__info {
      margin-inline: 0;
    }
    &__img {
      right: 0;
      transform: translateX(0);
      max-width: 50%;
    }
  }
  .hero {
    &__cover {
      align-items: start;
    }
    &__name,
    &__title {
      text-align: start;
    }
    &__cta {
      padding-right: 2rem;
    }
    &__socials {
      gap: 2rem;
      margin-top: 4rem;
    }
    &__img {
      opacity: 0.5;
      max-width: 100%;
      width: 100%;
    }
  }
  .about-cnt {
    column-gap: 3rem;
    &__info {
      flex-basis: 18.75rem;
    }
    &__img {
      flex-basis: 15rem;
    }
  }
  .footer-ctn {
    &__socials {
      max-width: fit-content;
    }
  }
  .wget {
    rotate: z -30deg;
    left: 90%;
  }
  .font-h1 {
    font-size: 3.25rem;
  }
  .font-p {
    &--big {
      font-size: 1.125rem;
    }
  }
  .top-navbar {
    padding-block: 1.5rem;
  }
  .hamburger {
    display: none;
  }
  .menu {
    &__box {
      position: static;
      visibility: visible;
      width: fit-content;
      max-height: 100%;
      padding-block: 0;
      padding-inline: 0;
      flex-direction: row;
      align-items: center;
      gap: 2rem;
      transition: none;
      transform: translate(0, 0);
      box-shadow: none;
      border: none;
      border-radius: 0;
      overflow: visible;
      background: none;
      backdrop-filter: none;
    }
    & .navlink {
      font-size: 1.25rem;
    }
  }
}
@media (min-width: 85rem) {
  .hero__img {
    opacity: 0.8;
    // & img {
    //   clip-path: polygon(9% 0%, 100% 0%, 92% 88%, 0% 95%);
    // }
    // &::after {
    //   content: '';
    //   position: absolute;
    //   inset: 3rem -1rem;
    //   border-top: 2px solid var(--c-accent);
    //   border-bottom: 2px solid var(--c-accent);
    //   transform: skew(-5deg, -5deg);
    //   z-index: -1;
    // }
    // &::before {
    //   content: '';
    //   position: absolute;
    //   inset: 0rem 1rem;
    //   border-left: 2px solid var(--c-accent);
    //   border-right: 2px solid var(--c-accent);
    //   transform: skew(-5deg, -5deg);
    //   z-index: -1;
    // }
  }
}
